import React from 'react'

import Layout from '../components/layout'
import Placeholder from '../components/placeholder'
import Seo from '../components/seo'
import {useIntl} from 'gatsby-plugin-intl'

const History = () => {
    const intl = useIntl()
    return (
        <Layout>
            <Seo title={intl.formatMessage({id: 'our_history'})}/>
            <Placeholder/>
        </Layout>
    )
}


export default History
